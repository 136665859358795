<template>
  <div class="feedback">
    <!-- <div class="mantil">意见反馈</div> -->
    <p class="til">反馈问题<span>（必选）</span></p>
    <div class="questionback">
      <div v-for="(item, index) in feedbackType" :key="index" :class="item.id == uid ? 'btn_backs' : 'btn_back'"
        @click="getfkid(item)">
        {{ item.name }}
      </div>
    </div>
    <p class="til tils">我要反馈<span>（必填）</span></p>
    <div class="btn_feedback">
      <el-input v-model="formData.content" type="textarea" :rows="4" :disabled="uid == null" placeholder="请输入您要反馈的内容"
        maxlength="200" show-word-limit />
    </div>
    <div class="btm_flex">
      <div class="pic">
        <el-upload ref="backgroundImage" :action="imgAction()" list-type="picture-card" name="upload"
          :headers="Domainjwt" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :limit="3">
          <i slot="default" class="el-icon-plus" />
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
          </div>
        </el-upload>
        <div class="pic_num">
          <span>{{ num }}</span>/3
        </div>
        <div class="pic_bot">点击上传图片（最多可上传3张，每张小于2M）</div>
      </div>
      <div class="btn_sub" @click="saveFeedback">确认提交</div>
    </div>
  </div>
</template>
<script>
import { imagecode, selectCode } from '@/api/register'
import { pca, pcaa } from 'area-data' // 城市数据
import { getPcUser, updatePcUser, updatePcUserPassword } from '@/api/user'
import PhoneChange from '@/components/mine/phoneChange.vue'
import { CouponsClient } from '@/api/mine/couponsClient'
import { setInfo, setToken, getInfo, getDomainToken } from '@/api/cookies'
import { getDnsOneApi } from '@/api/home'

const CouponsClients = new CouponsClient()
export default {
  components: { PhoneChange },
  data () {
    return {
      feedbackType: [
        { id: 1, name: '改进建议' },
        { id: 2, name: '授课师资问题' },
        { id: 3, name: '在线考试问题' },
        { id: 4, name: '辅导资料问题' },
        { id: 5, name: '班主任管理' },
        { id: 6, name: '其他' }
      ],
      textarea: '',
      num: 0,
      Domainjwt: {
        Domainjwt: getDomainToken()
      },
      uid: 1,
      imgs: [],
      formData: {
        name: '改进建议',
        content: null,
        img: '',
        userId: null
      },
      userInfo: {}
    }
  },

  async created () {
    this.userInfo = await getInfo()
    this.formData.userId = this.userInfo.id
  },
  methods: {
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess (res, i, fileList) {
      this.num = fileList.length
      if (fileList) {
        this.fileList = []
        this.imgs = []
        for (const items of fileList) {
          this.imgs.push(items.response.path)
        }
        this.formData.img = this.imgs.join(',')
      }
    },
    async saveFeedback () {
      if (this.formData.name && this.formData.content) {
        await CouponsClients.saveFeedback(
          this.formData.name,
          this.formData.content,
          this.uid,
          this.formData.img,
          this.formData.userId
        ).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: '反馈成功',
              type: 'success'
            })

            this.formData.content = ''
            this.formData.img = ''
            this.$refs.backgroundImage.clearFiles()
            this.num = 0
          } else {
            this.$message.error('反馈失败')
          }
        })
      } else {
        this.$message({
          message: '请将信息填写完整',
          type: 'warning'
        })
      }
    },
    getfkid (item) {
      this.uid = item.id
      this.formData.name = item.name
    }
  }
}
</script>
<style lang="less" scoped>
.feedback {
  width: 100%;
  height: 520px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 0 auto;
  .til {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    padding-top: 24px;
    margin-left: 40px;
    span {
      font-size: 14px;
      color: #999999;
    }
  }
  .tils {
    margin-top: 40px;
  }
  .questionback {
    display: flex;
    justify-content: start;
    margin-top: 24px;
    margin-left: 40px;
    .btn_back {
      width: 128px;
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #666666;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn_backs {
      width: 128px;
      height: 40px;
      background: #ffe6e4;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ff5e51;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #ff5e51;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .btn_feedback {
    width: calc(100% - 80px);
    margin: 0 auto;
    margin-top: 24px;

    border-radius: 8px 8px 8px 8px;
  }
}
::v-deep .el-textarea__inner {
  background: #f7f7f7 !important;
}
.btm_flex {
  display: flex;
  justify-content: space-between;
  ::v-deep .pic {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    margin-left: 40px;
    .el-upload--picture-card {
      width: 60px;
      height: 60px;
      background: #f7f7f7;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #dddddd;
      line-height: 60px;
    }
    .el-upload-list--picture-card {
      width: 60px;
      height: 60px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      line-height: 60px;
    }
    .el-upload-list__item {
      width: 60px;
      height: 60px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      line-height: 60px;
    }
    .pic_num {
      width: 23px;
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      margin-top: 46px;
      span {
        color: #ff5e51;
      }
    }
    .pic_bot {
      width: 297px;
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      margin-top: 46px;
      line-height: 14px;
    }
  }
  .btn_sub {
    width: 120px;
    height: 40px;
    background: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-top: 22px;
    margin-right: 40px;
    cursor: pointer;
  }
}
.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
  background-color: #f5f7f9;
}
</style>
